
.nc-content {
    // margin-top: 88px;
    padding-top: 40px;
}
.nc-hidden-header{
    height: 88px
}
/*CUSTOM*/

/*SCREEN PAGES*/
@media (min-width: 1536px) {
    .container-custom {
        max-width: 1536px;
        padding-right: 0!important;
        padding-left: 0!important;
    }
}
@media (min-width: 1280px){
    .container {
        max-width: 1280px;
        padding-right: 0 !important;
        padding-left: 0 !important;
    }
}

#nc-search-input-container{
    position: sticky;
    top: 13px;
    z-index: 1000;
    pointer-events: none;
}

#nc-search-input {
    pointer-events: all;
}

/*TYPOGRAPHY*/
.text-decor {
    text-decoration: underline !important;
}
.text-h2 {
    font-size: 32px;
    line-height: 40px;
    font-weight: 600;
}
@media (max-width: 700px) {
    .text-h2 {
        font-size: 24px;
        line-height: 32px;
    }
}
.text-h3 {
    font-size: 24px;
    line-height: 32px;
    font-weight: 800;
    margin-bottom: 8px;
}
@media (max-width: 700px) {
    .text-h3 {
        font-size: 18px;
        line-height: 24px;
    }
}
.text-h4 {
    font-size: 22px;
    line-height: 28px;
    font-weight: 600;
    margin-bottom: 8px;
}
@media (max-width: 700px) {
    .text-h4 {
        font-size: 17px;
        line-height: 23px;
    }
}
.text-h5 {
    font-size: 20px;
    line-height: 26px;
    font-weight: 600;
    margin-bottom: 8px;
}
@media (max-width: 700px) {
    .text-h4 {
        font-size: 17px;
        line-height: 23px;
    }
}
.text-18px_bold {
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
}
.text-17px_reg {
    font-size: 17px;
    line-height: 26px;
}
.text-17px_semi {
    font-size: 17px;
    line-height: 26px;
    font-weight: 600;
}
.text-16px_reg {
    font-size: 16px;
    line-height: 22px;
}
.text-16px_semi {
    font-size: 16px !important;
    line-height: 22px;
    font-weight: 600 !important;
}
.text-16px_bold {
    font-size: 16px;
    line-height: 22px;
    font-weight: 700;
}


.text-15px_reg {
    font-size: 15px;
    line-height: 22px;
    font-weight: 400 !important;
}
.text-15px_semi {
    font-size: 15px  !important;
    line-height: 20px !important;
    font-weight: 600 !important;
}
.text-15px_button {
    font-size: 15px;
    line-height: 16px;
    font-weight: 600;
}
.text-14px_semi {
    font-size: 14px;
    line-height: 18px;
    font-weight: 600;
}
.text-14px_reg {
    font-size: 14px !important;
    line-height: 18px;
}
.text-13px_reg {
    font-size: 13px;
    line-height: 16px;
}

/*CARD*/
.card-content {
    border: solid 1px #E5E7EB;
    padding: 14px;
}

/*MARGINS*/
.m-auto {
    margin: auto 0;
}

.m-zero {
    margin: 0 !important;
}
.mt-2px {
    margin-top: 2px;
}
.mt-4px {
    margin-top: 4px;
}
.mt-20px {
    margin-top: 1.25rem;
}
.mb-0 {
    margin-bottom: 0 !important;
}
.mb-4px {
    margin-bottom: 4px;
}
.mb-8px {
    margin-bottom: 8px !important;
}
.mb-12px {
    margin-bottom: 12px;
}
.mb-24px {
    margin-bottom: 1.25rem !important;
}
.mb-40px {
    margin-bottom: 2.5rem !important;
}
.ml-4px {
    margin-left: 4px;
}
.ml-8px {
    margin-left: 8px;
}
.mr-8px {
    margin-right: 8px;
}
.mr-16px {
    margin-right: 16px;
}
.mtb-4 {
    margin: 4px 0!important;
}
.mtb-20 {
    margin: 1.25rem 0;
}
.mlr-40 {
    margin: 0 0.5rem;
}

/*PADDINGS*/
.pt-16px {
    padding-top: 1rem;
}
.pb-1rem {
    padding-bottom: 1rem !important;
}
.pl-0 {
    padding-left: 0 !important;
}


/*PILLS*/
.pill-listed {
    background-color: #219653;
    padding: 0.25rem 0.75rem 0.15rem 0.65rem;
    font-size: 13px !important;
    font-weight: 600 !important;
}
.pill-listed-mine {
    background-color: #6192E6;
    padding: 0.15rem 0.55rem 0.15rem 0.45rem;
    font-size: 13px !important;
    font-weight: 600 !important;
}
.pill-restaurant {
    background-color: #C0D3F5;;
    padding: 0.5rem 0.75rem;
}

/*BUTTONS*/
.button-tertiary {
    display: flex;
    align-items: center;
    margin-top: 12px !important;
}
.button-sec {
    height: 2.125rem !important;
    color:#02203E;
    padding: 0 0.85rem !important;
    line-height: 0.05rem !important;
    font-size: 14px !important;
    font-weight: 600 !important;
}
.border-bolder {
    border-width: 2px;
}
.button-edit {
    line-height: 0.05rem !important;
    font-size: 14px !important;
}
.button-link {
    color: #3366DD;
}

/*COLORS*/
.required {
    color: #DA1414;
}
.link {
    color: #374EBD;
}
.border {
    border: 1px solid #E7E8EC;
}
.border-size {
    border-width: 2px;
}
/*LAYOUT*/
.flex-sm {
    flex: 0.5 1;
}
.flower-border {
    position: absolute;
    top: 0
}
.elements_apart {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}
.flex-start {
    align-items: flex-start !important;
}
.align-center {
    align-items: center !important;
}

.justify-start {
    justify-items: flex-start !important;
}
.justify-content-center {
    justify-content: center !important;
}
.justify-end {
    justify-content: flex-end !important;
}


.center {
   align-items: center;
}

.border-radius {
    border-radius: 1000px;
}
.index-1 {
    z-index: 1000 !important;
}
.index-0 {
    z-index: 999 !important;
}

.business-logo {
    border-image-source: url(../images/icons/star.png);
    border-image-slice: 20;
    border-image-width: 20;
    border-image-outset: 1;
    border-image-repeat: round;
}
.business-logo-border{
    border: 1px red solid;
}
.icon-size {
    font-size: 24px;
}
.small-text {
    font-size: 11px;
    font-weight: 700;
    letter-spacing: 0.2px;
}

.no-cards-container {
    padding-top: 10rem;
    padding-bottom: 10rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.button-profile {
    height: auto !important;
}

.input-error {
    color: red;
    display: flex;
    justify-content: flex-end;
}

.invalid-input {
    background-color: ivory;
    border: none;
    outline: 2px solid red;
    border-radius: 5px;
}

.clipboard-copy-container {
    display: flex;
    justify-content: flex-end;
}

.clipboard-copy-container > svg:hover {
    cursor: pointer;
}

.card-info-container {
    height: 20px;
}

/*CARD LOGO IMAGE*/

.focus-light:focus {
    border-color: #02203E !important;
    outline: 2px solid transparent;
    outline-offset: 2px;
    --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: #02203E !important;
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow) !important;
}

.focus-dark:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
    --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color2: #6192E6 !important;
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color2) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow) !important;
    border-color: #6192E6 !important;
}

/*grid*/
.grid-col-size {
  grid-template-columns: minmax(0, 0.8fr) minmax(0, 1fr) 80px;
  grid-gap: 16px 4px;
}
.grid-border {
    border-bottom: 1px solid #E5E7EB !important;
}
.card-number {
    max-width: 500px;
    border: 1px solid #E5E7EB;
    border-radius: 12px ;
    padding: 16px 12px 16px;
    margin: 24px 0 !important;
}
.full-screen {
    height: 60vh !important;
}
