.nc-MainNav1 {
  &.notOnTop {
    @apply bg-white dark:bg-neutral-900 backdrop-blur-2xl bg-opacity-70 dark:bg-opacity-60 shadow-sm dark:border-b dark:border-neutral-700;
  }
}

.nc-HeaderLogged {
    border-bottom: 1px solid rgba(102, 102, 102, 0.20);
    margin-bottom: 40px;
    position: fixed; /* Set the navbar to fixed position */
    top: 0; /* Position the navbar at the top of the page */
    width: 100%; /* Full width */
    z-index: 1000;
}

.nc-Header2 {
    border-bottom: 1px solid rgba(102, 102, 102, 0.20);
    margin-bottom: 40px;
    position: fixed; /* Set the navbar to fixed position */
    top: 0; /* Position the navbar at the top of the page */
    width: 100%; /* Full width */
    z-index: 1000;
}
